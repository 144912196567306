import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { CadAlertComponent } from 'src/app/component/cad-alert/cad-alert.component';

@Injectable({
  providedIn: 'root'
})
export class MenssagemService {
  public loading:any;

  // isToastPresent = false;
  constructor(public alertController: AlertController
    , public loadingCtrl: LoadingController
    , public toastController: ToastController
    , private dialog: MatDialog) { 
  }

  async alerta(mensagem:string) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: mensagem,
      textAccept: 'OK'
    };
    dialogConfig.panelClass = "my-custom-modal-alert-material-css";
    this.dialog.open(CadAlertComponent, dialogConfig);
  }

  async sucesso(mensagem:string) {
    const toast = await this.toastController.create({
      message: mensagem,
      duration: 3000,
      position: "top",
      color:"success"
    });
    toast.present();
  }

  private async sucessoAlert(mensagem:string) {
    const alert = await this.alertController.create({
      header: 'Sucesso',
      message: mensagem,
      buttons: ['OK']
    });

    await alert.present();
  }


  async error(mensagem:string) {
    // this.errorAlert(mensagem);
    // if (!this.isToastPresent) {
      // this.isToastPresent = true;
  
      const toast = await this.toastController.create({
        message: mensagem,
        duration: 3000,
        position: "top",
        color: "danger",
      });
      // toast.dismiss(() => {
      //   this.isToastPresent = false;

      // });
      toast.present();
    // }
  }

  private async errorAlert(mensagem:string) {
    const alert = await this.alertController.create({
      header: 'Erro',
      message: mensagem,
      buttons: ['OK']
    });

    await alert.present();
  }

  // confirme(mensagem:string, okHandler) {
  //   this.confirme(mensagem, okHandler, () => {})
  // }

  confirmar(mensagem:string, okHandler, cancelHandler?) {
    this.confirmeAlert(mensagem, okHandler, cancelHandler)
  }

  private async confirmeAlert(mensagem:string, okHandler, cancelHandler) {

    const alert = await this.alertController.create({
      header: 'Confirmar',
      message: mensagem,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancelar',
          cssClass: 'secondary',
          handler: (blah) => {
            if (cancelHandler) {
              cancelHandler()
            }
          }
        }, {
          text: 'Ok',
          handler: () => {
            if (okHandler) {
              okHandler()
            }
          }
        }
      ]
    });

    await alert.present();
  }

  public async confirmeAlertAgendar(mensagem:string, okHandler, cancelHandler, agendarHandler) {

    const alert = await this.alertController.create({
      header: 'Confirmar',
      message: mensagem,
      buttons: [
        {
          text: 'Agendar',
          role: 'agendar',
          cssClass: 'secondary',
          handler: () => {
            if (agendarHandler) {
              agendarHandler()
            }
          }
        },
        {
          text: 'Cancelar',
          role: 'cancelar',
          cssClass: 'secondary',
          handler: (blah) => {
            if (cancelHandler) {
              cancelHandler()
            }
          }
        }
        , {
          text: 'Ok',
          handler: () => {
            if (okHandler) {
              okHandler()
            }
          }
        }
        
      ]
    });

    await alert.present();
  }

  public async confirmeAlertInpupt(mensagem:string, okHandler, cancelHandler) {

    const alert = await this.alertController.create({
      header: 'Confirmar',
      message: mensagem,
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Placeholder 1'
        }
      ],
      buttons: [
     
        {
          text: 'Cancelar',
          role: 'cancelar',
          cssClass: 'secondary',
          handler: (blah) => {
            if (cancelHandler) {
              cancelHandler()
            }
          }
        }
        , {
          text: 'Ok',
          handler: () => {
            if (okHandler) {
              okHandler()
            }
          }
        }
        
      ]
    });

    await alert.present();
  }

  private showLoading:boolean = false;
  async mostraLoading() {
    this.showLoading = true;
    this.loading = await this.loadingCtrl.create({
      message: 'Por favor aguarde...',
      duration: 25000,
      backdropDismiss: true,
    });
    
    await this.loading.present();
    //Pode ser que já mandamos esconder o componente, mas ainda nem carregou na tela, por isso temos que validar aqui.
    if (!this.showLoading) {
      this.escondeLoading();
    }
  }

  escondeLoading() {
    this.showLoading = false;
    if (this.loading) {
      this.loading.dismiss()
    }
  }
}
